/* You can add global styles to this file, and also import other style files */
body.overlay-open {
    overflow: hidden;
}

.pointer-none {
    pointer-events: none;
}

.oiv-height-100 {
    height: 100%;
}
